<template>
  <div class="tutorial">
    <div class="container" style="text-align: center">
      <div v-if="isLanguageAvailable()">
        <img
          :src="`/tutorial_assets/${currentLanguage.key}-${i}_cropped.png`"
          v-for="i in langs[currentLanguage.key]"
          v-bind:key="i"
          style="margin-top: 24px;"
        ></div>
      <div v-if="!isLanguageAvailable()">
        <img
          :src="`/tutorial_assets/en-${pad(i, 2)}_cropped.png`"
          v-for="i in 11"
          v-bind:key="i"
          style="margin-top: 24px;"
        ></div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';

export default {
  name: 'Tutorial',
  data() {
    return {
      langs: {
        uk: 12,
        pl: 13,
        es: 12,
        ru: 12,
        it: 12,
        fr: 12,
        en: 11,
        de: 12,
        pt: 7,
        hu: 6,
        sr: 6,
        el: 7,
        cs: 6,
        us: 11,
        sk: 6,

      },
    };
  },
  methods: {
    pad(n, size) {
      let s = String(n);
      while (s.length < (size || 2)) {
        s = `0${s}`;
      }
      return s;
    },
    isLanguageAvailable() {
      return this.langs[this.currentLanguage.key] !== undefined;
    },
  },
  computed: {
    ...mapState({
      currentLanguage: (state) => state.language.currentLanguage,
    }),

  },
};
</script>

<style lang="scss" scoped src="./Tutorial.scss"/>
