<template>
  <div class="shop">
    <div class="container">
      <section class="shop__grid">
        <carousel :per-page="1" class="shop__carousel_wrapper">
          <slide>
            <img src="/assets/shop/slider3.png">
          </slide>
        </carousel>

        <base-input placeholder="Search..." v-model="searchValue"/>

        <ul class="shop__grid-list" ref="tileList">
          <div class="shop__tile" v-for="shop in filteredShops" :key="shop.id">
            <img :src="shop.data.image.url" style="max-height: 200px;">
            <br/>
            <!--            <p class="shop__link">{{ shop.data.shop_name[0].text }}</p>-->
            <p class="shop__link ">
              {{ shop.data.description[0].text }}
            </p>
            <br/>
            <base-button thin @click="redirect(shop)">
              Buy now
            </base-button>
          </div>
          <!--          <div class="shop__tile" v-for="i in 6" :key="i">-->
          <!--            <img src="/assets/shop/your-brand-2.png" style="max-height: 200px;">-->
          <!--            <br/>-->
          <!--&lt;!&ndash;            <p class="shop__link">Your brand</p>&ndash;&gt;-->
          <!--&lt;!&ndash;            <p class="shop__description">&ndash;&gt;-->
          <!--&lt;!&ndash;              www.your-brand.com&ndash;&gt;-->
          <!--&lt;!&ndash;            </p>&ndash;&gt;-->
          <!--            <br/>-->
          <!--            <base-button thins>-->
          <!--              Buy now!-->
          <!--            </base-button>-->
          <!--          </div>-->
        </ul>
      </section>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Shop',
  data: () => ({
    shops: [],
    searchValue: '',
  }),
  async mounted() {
    const response = await this.$prismic.client.query(this.$prismic.Predicates.at('document.type', 'shop'));
    this.shops = response.results;
    // this.response = response
  },
  computed: {
    filteredShops() {
      return this.shops.filter((x) => x.data.shop_name[0].text.toString().toLowerCase().includes(this.searchValue.toString().toLowerCase()));
    },
  },
  methods: {
    redirect(shop) {
      this.$analytics.fbq.event(`ShopClick.${shop.data.shop_name[0].text.toString().replaceAll(' ', '_')}`);
      window.open(shop.data.link[0].text, '_blank').focus();
    },
  },
};
</script>
<style lang="scss" scoped src="./Shop.scss"/>
